import { Card } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  const render = () => {
    return (
      <div>
        <h1>Добро пожаловать в YagoWorld!</h1>
        <p><strong>Мир Яго</strong> — это уникальный фэнтези мир, где вы сможете основать своё поселение и развивать
          его через века. Вдохновлённая классическими военно-политическими играми, наша игра предлагает
          вам погрузиться в эпоху великих открытий, культурных революций и эпических сражений.</p>
        <h3>История мира</h3>
        <p>Игра начинается в Триморье, населенном людьми разных рас, примерно через 40 лет после
          прибытия первых кораблей эльниров. Людские племена, находившиеся в эпохе неолита, были
          слабо развиты, но эльниры принесли с собой обработку бронзы, письменность и даже магию.
          Почти полвека эльниры и люди налаживали первые контакты, узнавали больше друг о друге и
          торговали. Но сейчас эльниры решили основать свои первые полисы в Триморье.</p>
        <h3>Ваши возможности</h3>
        <p>В этой игре вы сможете:</p>
        <ul>
          <li><strong>Основать своё поселение</strong> и развивать его через века.</li>
          <li><strong>Вводить различные законы</strong> и управлять развитием культуры и религии.</li>
          <li><strong>Торговать и воевать с соседями</strong>, подчинять своей власти другие поселения и оказывать на них влияние.</li>
          <li><strong>Следить за жизнью жителей</strong>, их счастьем, свободой, развитием и лояльностью.</li>
          <li><strong>Обеспечивать безопасность</strong> и чистоту в поселении, делая его красивым и процветающим..</li>
        </ul>
        <h3>Присоединяйтесь к нам!</h3>
        <p>Погрузитесь в мир Яго, где каждый ваш выбор имеет значение. Основайте своё поселение, развивайте
          его и станьте великим правителем, который оставит свой след в истории этого удивительного мира.</p>
        <p>Начните своё приключение в Яго уже сегодня и покажите, на что вы способны!</p>
        <Card>
          <ul>
            <li><Link to='/map'>Карта Триморья</Link></li>
            <li><a href='https://vk.com/club225775878' target='_blank'>Группа игры в ВК</a></li>
          </ul>
        </Card>
      </div>
    )
  }

  return render();
};

export default Home;
