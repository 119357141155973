import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../appStore';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { Card, Typography } from '@mui/material';

import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css';
import '../app/customLeafletAttribution.css';

const MapPage: React.FC = () => {
    const state = useAppSelector(state => state.counter)
    const dispatch = useAppDispatch()

    const defaultZoom = 6
    const minZoom = 5
    const maxZoom = 6

    const [locationCoordinates, setLocationCoordinates] = React.useState<LatLng | null>(null);

    interface LatLng {
        lat: number;
        lng: number;
    }

    const defaultIcon = L.icon({
        iconUrl: markerIcon,
        shadowUrl: markerShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const LocationMarker: React.FC<{ setPosition: (position: LatLng) => void }> = ({ setPosition }) => {
        useMapEvents({
            click(e) {
                setPosition(e.latlng);
            },
        });

        return null;
    };

    const ZoomMap: React.FC = () => {
        const map = useMapEvents({
            zoomend() {
                let zoom = map.getZoom();
                if (zoom < minZoom) {
                    zoom = minZoom;
                    map.setZoom(zoom);
                }
                if (zoom > maxZoom) {
                    zoom = maxZoom;
                    map.setZoom(zoom);
                }
            },
        });
        return null;
    };

    const renderMapCoordinate = () => {
        let text = locationCoordinates == null
            ? 'Кликните на карту, чтобы узнать координаты локации'
            : `Координаты локации: ${locationCoordinates.lat.toFixed(3)} : ${locationCoordinates.lng.toFixed(3)}`;
        return (
            <Card>
                <Typography variant="subtitle2" gutterBottom style={{ textAlign: "center", fontWeight: "bold" }}>
                    {text}
                </Typography>
            </Card>
        )
    }

    const renderMap = () => {
        return (
            <MapContainer
                center={[33, 37]}
                zoom={defaultZoom}
                scrollWheelZoom={true}
                maxBounds={[[20.8, 14], [48, 59]]}>
                <TileLayer
                    url="./images/worldmap/{z}-{x}-{y}.jpg"
                    noWrap
                />
                <LocationMarker setPosition={setLocationCoordinates} />
                {locationCoordinates &&
                    <Marker position={locationCoordinates} icon={defaultIcon} />
                }
                <ZoomMap />
            </MapContainer>
        )
    }

    const render = () => {
        return (
            <>
                {renderMap()}
                {renderMapCoordinate()}
            </>
        )
    }

    return render();
};

export default MapPage;
